import { useEffect, useState } from "react";
var Recaptcha = require('react-recaptcha');


const SubscribeForm = () => {
  const [recaptchaToken, updateRecaptchaToken] = useState(null);
  const callback = () => {
    console.log('Captcha is ready!!!!');
  };

  const verifyCallback = (response) => {
    updateRecaptchaToken(response);
    console.log("test");
    console.log(response);
  };

  return (
    <div className="subscribe-form">
      <input placeholder="test" />
      <input placeholder="test" />
      <input placeholder="test" />
      <input placeholder="test" />

      <Recaptcha
        sitekey="6LdFuu8UAAAAALT-oMuP77qqXPICvNyTodVer82C"
        render="onload"
        verifyCallback={verifyCallback}
        onloadCallback={callback}
        expiredCallback={() => updateRecaptchaToken(null)}
        theme="dark"
      />
      {/* {recaptchaToken} */}
    </div>
  );
}

export default SubscribeForm;
