import "./timelinestyle.css";
const TimeLine = () => {
  return (
    <section id="roadmap" className="timeline">
      <h1 id="timeline-title-road">Roadmap</h1>
      <ul>
        {/* <li className="divider-list">
          November-December 2021
        </li> */}

<li>
          <div>
            <time>Proxies initial launch of 500 NFTs</time>
            {/* Presenting Proxies to Cardano Community. */}
          </div>
        </li>
        <li>
          <div>
            <time>Community and relationship building</time>
            {/* Presenting Proxies to Cardano Community. */}
          </div>
        </li>

        <li>
          <div>
            <time>Holders verification</time>
            {/* We’ve planned a lot. Let's take a first step and start Proxies mission before the mint, before the beginning. */}
          </div>
        </li>
        {/* <li className="divider-list">
          January 2022
        </li> */}
        <li>
          <div>
            <time>$Proxie token release</time>
            {/* We are releasing 500 NFTs (Proxies included in the collection: Destiny, Cyberiada, Moneda). We initiate our mission on the blockchain and you will be a part of it. */}

          </div>
        </li>
        <li>
          <div>
            <time>Community rewards airdrop</time>
            {/* Using our unique “beehive” system we will meet and discuss each Proxie’s mission and vision. */}
          </div>
        </li>
        <li>
          <div>
            <time>Main launch 4500 NFTs</time>
            {/* We start building the community we would love to be part of. Proxies holders choose the path that aligns with their values and interests and jump into the beehive. */}

          </div>
        </li>
        <li>
          <div>
            <time>Discord tokens transfer system</time>
            {/* Thank you for being part of Proxies family and for your contribution to the community */}

          </div>
        </li>

        {/* <li className="divider-list">
          February 2022
        </li> */}
        <li>
          <div>
            <time>
              Artists launchpad roadmap & plan
            </time>
            {/* We will be releasing 4500 NFTs to encourage growth of new members. */}

          </div>
        </li>
        <li>
          <div>
            <time>
              The sixth Proxie will be airdroped to 5 Proxies set holders
            </time>
            {/* At this time Proxies will request proposals/discussions from each of their NFT holders to vote and decide the future of their beehive. */}

          </div>
        </li> <li>
          <div>
            <time>
              Proxies’ Stakepool
            </time>
            {/* Cyberiada will have a verification system for NFT holders connected to Discord */}

          </div>
        </li> <li>
          <div>
            <time>
              Each specific Proxie will have its utility in the near future
            </time>
            {/* Networking and building together; Your voice is the vote we, carefully, listen to. */}
          </div>
        </li>
        {/* <li className="divider-list">
          Q2-Q3 2022
        </li> */}
        <li>
          <div>
            <time>Token listing & liquidity on DEXes</time>
            {/* Her community chooses the causes and NGOs to collaborate with. */}


          </div>
        </li>
        <li>
          <div>
            <time>DAO establishment</time>
            {/* Upgrade your Proxie! Various accessories with unique rarities and gemstones will be airdropped for your Proxies to existing NFT holders. */}




          </div>
        </li>
        <li>
          <div>
            <time>3D spaces & Metaverse Interaction Exploration</time>
            {/* <br /><br /><i>*Governance airdrop guaranteed for holders of all 5 Proxies</i> */}

          </div>
        </li>
      </ul>
    </section>
  )
}
export default TimeLine;