import "./styles.css";
import { useState, useEffect } from "react";
import React from "react"
import SubscribeForm from "./Components/SubscribeForm";
import axios from "axios";

import { Row, Col, Container } from 'react-bootstrap';
import Slider from "react-slick";
// import "./style_roadmap.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { InView } from 'react-intersection-observer';
import TimeLine from "./Components/Timeline"
import Header from "./Components/HeadMenu";
// import { useInView } from 'react-intersection-observer/polyfilled';
// import ScrollSnap from 'scroll-snap'
// import { render } from "@testing-library/react";

// import $ from 'jquery';
// SMOOTH SCROLLING SECTIONS
// $(document).on("load",function () {

//   $('a[href*=\\#]:not([href=\\#])').on("click", function () {
//     if (window.location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
//       || window.location.hostname == this.hostname) {

//       var target = $(this.hash);
//       target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
//       if (target.length) {
//         $('.body-nav-test').animate({
//           scrollTop: target.offset().top
//         }, 1000);
//         return false;
//       }
//     }
//   });
//   console.log("ready!");
// });
// var Recaptcha = require('react-recaptcha');


function BecomeAMember() {
  return (
    <Container className="align-center wanttoget">
      <h1 className="align-center">Become a member</h1>
      <div className="wanttoget-text">Membership into each community is obtained via ownership of its Proxy’s NFT. 
      <br/><br/>

This creates an incentive for members to engage in their community. A token entitles a member to: read the content, write and vote on its corresponding channel, vote on general proposals on the project’s goals, partake in our unpromised surprise airdrops.

        {/* <br /><br />
        This creates an incentive for members to engage in their community.
        <br /><br />
        A token entitles a member to: read the content, write and vote on its corresponding channel, vote on general proposals on the project’s goals, partake in currency airdrops and other NFT giveaways. */}
      </div>
      <a className="wanttoget-link">How to buy?</a>
    </Container>
  )
}

function JoinComunityBlock() {
  return (
    <Container className="join-comunity">
      <Row>
        <div className="join-comunity-text">Join our awesome community</div>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          {/* <Col md={1} lg={1} className="flex d-sm-none d-md-block align-center social d-none justify-content-between noPadding"> */}
          <a target="_blank" href="https://twitter.com/Proxies_NFT"><img className="img-social" src="social_twitter.png" /></a>
          <a target="_blank" href="https://discord.gg/Na9R3hGYqY"><img className="img-social" src="social_discord.png" /></a>
          {/* </Col> */}
        </Col>
      </Row>
    </Container>
  )
}

function Footer() {
  let [scriptLoad, updateScriptLoad] = useState(false);
  // if (!scriptLoad) {
  //   updateScriptLoad(true);
  //   setTimeout(() => {
  //     (function () {
  //       "use strict";

  //       // define variables
  //       var items = document.querySelectorAll(".timeline li");

  //       // check if an element is in viewport
  //       // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
  //       function isElementInViewport(el) {
  //         console.log("blea")
  //         var rect = el.getBoundingClientRect();
  //         return (
  //           rect.top >= 0 &&
  //           rect.left >= 0 &&
  //           rect.bottom <=
  //           (window.innerHeight || document.documentElement.clientHeight) &&
  //           rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  //         );
  //       }

  //       function callbackFunc() {
  //         for (var i = 0; i < items.length; i++) {
  //           if (isElementInViewport(items[i])) {
  //             items[i].classList.add("in-view");
  //           }
  //         }
  //       }

  //       // listen for events
  //       window.addEventListener("load", callbackFunc);
  //       window.addEventListener("resize", callbackFunc);
  //       window.addEventListener("scroll", callbackFunc);
  //     })();
  //   }, 1000);
  // }
  return (
    <Container style={{ postion: "relative", overflow: "hidden", zIndex: 10000000 }} className="footer">
      <Row className="first-row">
        <Col className="d-none d-sm-none d-md-block" md={1}><img className="cardano" src="cardano.png" /></Col>
        <Col className="d-none d-sm-none d-md-block" sm={12} md={5} >
          <div className="cardano-title-footer">Cardano</div>
        </Col>
        <Col></Col>
        {/* <Col sm={12} md={2} className="footer-menu" >Menu</Col> */}
        <Col className="links-footer d-xs-block d-sm-block d-md-none" sm={12} md={2} >
          <ul>
            <li><a href="#">Discover</a></li>
            <li><a href="#">How to buy</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Termns and conditions</a></li>
            <li><a href="#">Verification</a></li>
          </ul>
        </Col>
        <Col></Col>
        <Col sm={12} md={2} className="footer-menu"><a href="https://cardanoproxies.io/Proxies_Whitepaper.pdf">Whitepaper</a></Col>

        <Col sm={12} md={2} className="footer-menu">Follow us</Col>

        <Col className="links-footer d-xs-block d-sm-block d-sm-none" sm={12} md={2} >
          <ul>
            <li><a target="_blank"  href="https://twitter.com/Proxies_NFT">Twitter</a></li>
            <li><a target="_blank" href="https://discord.gg/Na9R3hGYqY">Discord</a></li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col md={1}></Col>
        <Col className="d-xs-block d-xs-block d-md-none" md={1}><img className="cardano" src="cardano.png" /></Col>
        <Col className="d-xs-block d-sm-block d-md-none" sm={12} md={5} >
          <div className="cardano-title-footer">Cardano</div>
        </Col>
        <Col sm={12} md={5} >

          <div className="cardano-hash-footer">
            <strong>Policy ID:</strong><br />
            <strong> Cardano Proxies's:</strong>
            6ce2d3c06e323d522d0d1c67b4ebae39d62549acc633733e357d6027<br />
            <strong>Cardano Proxies Special's:</strong>00c5ee75c364ae10409040172538e3d5d295aafc18bfbf4d5e26f352</div>
        </Col>
        <Col></Col>
        {/* <Col className="links-footer d-none d-sm-none d-md-block" sm={12} md={2} >
          <ul>
            <li><a href="#">Discover</a></li>
            <li><a href="#">How to buy</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Termns and conditions</a></li>
            <li><a href="#">Verification</a></li>
          </ul>
        </Col> */}
        <Col></Col>
        <Col className="links-footer d-none d-sm-none d-md-block" sm={12} md={2} >
          <ul>
            <li><a href="https://twitter.com/Proxies_NFT">Twitter</a></li>
            <li><a href="https://discord.gg/Na9R3hGYqY">Discord</a></li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

class MySlider extends React.Component {
  // container = React.createRef()

  // bindScrollSnap() {
  //   const element = this.container.current
  //   const snapElement = new ScrollSnap(element, {
  //     snapDestinationY: '90%',
  //   })

  //   snapElement.bind(callback)
  // }

  // componentDidMount() {
  //   this.bindScrollSnap()
  // }

  render() {
    return (
      <div className="slider-block-s">

        {/* <h2> Single Item</h2> */}
        <Slider  {...{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }} >
          {(window.innerWidth >= 767) && <div className="slide"  >
            <div className="slide-content first-slider"><h1 className="slide-title">Our Proxies are part of our family of Beehives. 
</h1>
        <div className="slide-text-content">
        There are 5 hives that you will choose to support. Each NFT holder represents the Proxy they are supporting. Whether it is the Environment with Gaya, or the technology for Cyberiada. They can use their NFTs to vote and move the project in that direction.
We are beehives buzzing around to build a honeycomb of social values striving for good values. Why did we adopt the "beehive" system on our discord? The beehive, in nature, is usually led by a queen bee, an entity that represents fertility (in our case, productivity), community gathering and a sense of belonging.
          <br /><br />
          {/* In order to fulfill our purposes we evolve through progressive versions of ourselves. */}
          <br /><br />

          <strong>Meet the Proxies.</strong>
            </div></div>
          </div>
          }
          <div className="slide">
            <div className="slide-content">
              <img className="image-in-slide" src="Cyberiada_desktop.jpg" />
              <div className="slide-text-content">
                <div className="character-name">Cyberiada</div>
                <div className="skils"><strong>DNA:</strong> The Nerd</div>
                <div className="character-description">
                Combine your minds on the Cardano blockchain to contribute to our open-source projects. We thrive from contributions from you to be at the forefront of all things technology and Cyberiada. As a Cyberiada community, we will recruit other Nerds to improve the Cardano tech and documentation. Let's get Coding!
                </div>
                {/* <div className="text-unde-description-character">Open the door, let me out, let me-out, let me-aow, let meaow, meaow!</div> */}
                <div className="discover-more">Discover more...</div>
              </div>
            </div>
          </div>
          <div className="slide">
            <div className="slide-content"><img className="image-in-slide" src="Moneda_desktop.jpg" /><div className="slide-text-content">
              <div className="character-name">Moneda</div>
              <div className="skils"><strong>DNA:</strong> The Thrifty</div>
              <div className="character-description">
              I am the Proxy that gathers coins for the people and brings it back to the colony. We will work together with me to create revenue streams to fund our Proxy hives. As a part of Moneda we will work together to create a sustainable flow of income for the innovative entrepreneurs, financiers, and those thrifty minded individuals.

              </div>
              {/* <div className="text-unde-description-character">Open the door, let me out, let me-out, let me-aow, let meaow, meaow!</div> */}
              <div className="discover-more">Discover more...</div>
            </div></div>
          </div>
          <div className="slide">
            <div className="slide-content"><img className="image-in-slide" src="Destiny_desktop.jpg" /><div className="slide-text-content">
              <div className="character-name">Destiny</div>
              <div className="skils"><strong>DNA:</strong> Philantropist</div>
              <div className="character-description">
              Empathy is in my DNA: there should always be a place in our hearts and minds for those who need help. Heart disease accounts for millions of untimely deaths throughout the world’s poorest continent. So the proceeds from my 1000 NFTs will be donated to the fight against cardiovascular diseases in Africa and maintaining mental health in the community.
               </div>
              {/* <div className="text-unde-description-character">Open the door, let me out, let me-out, let me-aow, let meaow, meaow!</div> */}
              <div className="discover-more">Discover more...</div>
            </div></div>
          </div>
          {/* <div className="slide">
            <div className="slide-content"> <img className="image-in-slide" src="gaya.png" /> <div className="slide-text-content">
              <div className="character-name">Gaya</div>
              <div className="skils"><strong>DNA:</strong> tech, engineering</div>
              <div className="character-description">
                Roll over and sun my belly russian blue or eat a plant, kill a hand meow to be let out catching very fast laser pointer but walk on keyboard . My cat stared at me he was sipping his tea, too thug cat or sleep everywhere, but not in my bed so if it fits
              </div>
              <div className="text-unde-description-character">Open the door, let me out, let me-out, let me-aow, let meaow, meaow!</div>
              <div className="discover-more">Discover more...</div>
            </div>
            </div>
          </div>
          <div className="slide">
            <div className="slide-content"><img className="image-in-slide" src="easella.png" /> <div className="slide-text-content">
              <div className="character-name">Esella</div>
              <div className="skils"><strong>DNA:</strong> tech, engineering</div>
              <div className="character-description">
                Roll over and sun my belly russian blue or eat a plant, kill a hand meow to be let out catching very fast laser pointer but walk on keyboard . My cat stared at me he was sipping his tea, too thug cat or sleep everywhere, but not in my bed so if it fits
              </div>
              <div className="text-unde-description-character">Open the door, let me out, let me-out, let me-aow, let meaow, meaow!</div>
              <div className="discover-more">Discover more...</div>
            </div></div>
          </div> */}


        </Slider>
      </div>
    );
  }
}


function RoadMap() {

  const [activeState, updateActiveState] = useState(null);

  return (
    <div className="body-nav-test" data-spy="scroll" data-target="#navbar-example">

      <div className="nav_new_wrapper" >
        <nav className="nav__wrapper" id="navbar-example">
          <ul className="nav">
            <li role="presentation" className={activeState == "section1" ? "active" : ""}>
              <a href="#section1">
                <span className="nav__counter">01</span>
                <h3 className="nav__title">Community-building</h3>
                <p className="nav__body"></p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section2" ? "active" : ""}>
              <a href="#section2">
                <span className="nav__counter">02</span>
                <h3 className="nav__title">Listing on major marketplaces
                </h3>
                <p className="nav__body"></p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section3" ? "active" : ""}>
              <a href="#section3">
                <span className="nav__counter">03</span>
                <h3 className="nav__title">Launch 1:</h3>
                <p className="nav__body">release of 500 NFTs. Proxies wake up in the blockchain, at the start of their mission</p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section4" ? "active" : ""}>
              <a href="#section4">
                <span className="nav__counter">04</span>
                <h3 className="nav__title">
                  Section 4 Title
                </h3>
                <p className="nav__body">
                  Community onboarding, discuss the Proxies you received; contest and airdrops for members.
                </p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section5" ? "active" : ""}>
              <a href="#section5">
                <span className="nav__counter">05</span>
                <h3 className="nav__title">Meet and greet </h3>
                <p className="nav__body">Each Proxy meets her community members and discuss mission and collaboration.</p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section6" ? "active" : ""}>
              <a href="#section6">
                <span className="nav__counter">06</span>
                <h3 className="nav__title">Launch 2:</h3>
                <p className="nav__body">Release of 4500 NFTs, Proxies community grows, meet new members.</p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section7" ? "active" : ""}>
              <a href="#section7">
                <span className="nav__counter">07</span>
                <h3 className="nav__title">
                  Community on fire:                  </h3>
                <p className="nav__body">
                  Each Proxy starts her mission with her community. Members vote and decide the future of her beehive.
                </p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section8" ? "active" : ""}>
              <a href="#section8">
                <span className="nav__counter">08</span>
                <h3 className="nav__title">
                  Hodlers awards:
                </h3>
                <p className="nav__body">
                  The future is in your diamond hands, get rewarded for being member of Cardano Proxies
                </p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section9" ? "active" : ""}>
              <a href="#section9">
                <span className="nav__counter">09</span>
                <h3 className="nav__title two-row-title">
                  Destiny starts her mission in Africa
                </h3>
                <p className="nav__body">
                  Her community choses the NGO to collaborate with.
                </p>
              </a>
            </li>

            <li role="presentation" className={activeState == "section10" ? "active" : ""}>
              <a href="#section10">
                <span className="nav__counter">10</span>
                <h3 className="nav__title">
                  Accessories released:
                </h3>
                <p className="nav__body">
                  Upgrade your Proxy to the sky
                </p>
              </a>
            </li>

            {/* <li role="presentation" className={activeState == "section11" ? "active" : ""}>
              <a href="#section11">
                <span className="nav__counter">11</span>
                <h3 className="nav__title">
                  Section 11 Title
                  </h3>
                <p className="nav__body">
                  Sed sit amet justo sed odio tempus tempus. Vestibulum sed varius mi, sit amet condimentum lacus.
                  </p>
              </a>
            </li> */}

          </ul>
        </nav>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section1");
          }
          return inView ? window.location = "#section1" : "";
        }}>

          <section className="nav_section section section1" id="section1">

          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section2");
          }
          return inView ? window.location = "#section2" : "";
        }}>

          <section className="nav_section section section2" id="section2">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section3");
          }
          return inView ? window.location = "#section3" : "";
        }}>

          <section className="nav_section section section3" id="section3">
            {/* 3. Launch 1: */}
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section4");
          }
          return inView ? window.location = "#section4" : "";
        }}>

          <section className="nav_section section section4" id="section4">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section5");
          }
          return inView ? window.location = "#section5" : "";
        }}>

          <section className="nav_section section section5" id="section5">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section6");
          }
          return inView ? window.location = "#section6" : "";
        }}>

          <section className="nav_section section section6" id="section6">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section7");
          }
          return inView ? window.location = "#section7" : "";
        }}>

          <section className="nav_section section section7" id="section7">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section8");
          }
          return inView ? window.location = "#section8" : "";
        }}>

          <section className="nav_section section section8" id="section8">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section9");
          }
          return inView ? window.location = "#section9" : "";
        }}>

          <section className="nav_section section section9" id="section9">
          </section>
        </InView>
        <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section10");
          }
          return inView ? window.location = "#section10" : "";
        }}>

          <section className="nav_section section section10" id="section10">
          </section>
        </InView>

        {/* <InView threshold={0.4} delay={400} as="section" onChange={(inView, entry) => {
          if (inView) {
            updateActiveState("section11");
          }
          return inView ? window.location = "#section11" : "";
        }}>

          <section className="nav_section section section11" id="section11">
          </section>
        </InView> */}


      </div>
    </div>
  )
}

function SocialDinamics() {
  return (
    <div style={{ zIndex: 10000000, overflow: "hidden" }} className="social-dinamics">
      <h3 className="social-dinamics-title">Social dynamics</h3>
      <div className="social-body-text" style={{lineHeight: "36px"}}>

      We have established a unique social dynamic system called the “beehive” system in our server. This is where each Proxy will have 1000 NFTs for their supporters that allow others to vote on. Each Proxy will have a dedicated forum on Discord hosted by a devoted team member for individuals to come together to submit ideas and projects for the colony. You will congregate and discuss projects on specific Discord channels to create proposals for the beehives to vote on. 

        {/* Each community will mint 1,000 NFTs bearing the effigy of their respective Proxy.
        <br />
        <br />
        Members congregate on a Discord server where each community has its dedicated channel or beehive. Each beehive is moderated and stimulated by its Proxy, evolving on a road paved by its members.

        <br />
        <br />
        Each community’s Proxy will reward its most active users by giving them monetary and NFT rewards. */}

      </div>

    </div>
  )
}


function Main() {

  const [email, updateEmail] = useState("");
  const [wallet, updateWallet] = useState("");
  const [submiting, updateSubmiting] = useState(false);
  const [successful, updateSuccesfull] = useState(false);
  const [errorMessage, updateError] = useState("");
  const [recaptchaToken, updateRecaptchaToken] = useState(null);
  const callback = () => {
    console.log('Captcha is ready!!!!');
  };

  useEffect(() => {
    updateSuccesfull(false);
    updateError(false);
  }, [email, wallet])
  const submitRegistration = () => {
    updateError("");
    updateSuccesfull(false);
    updateSubmiting(true);
    axios.post("http://127.0.0.1:3000/subscribe/subscribe", {
      wallet,
      email,
      launch_id: "617fdd69f6586d54f449f7ae"
    })
      .then(response => {
        setTimeout(() => {

          updateSubmiting(false);
          updateSuccesfull(true);
        }, 1500);
      })
      .catch(errorResponse => {
        updateSubmiting(false);

        updateError(errorResponse.response.data.message)
      })
  }

  const verifyCallback = (response) => {
    updateRecaptchaToken(response);
    console.log("test");
    console.log(response);
  };
  return (
    <>
      {/* <div className="Main"> */}
      {/* <Container className="mx-0" > */}
      <Header/>
      {/* <SubscribeForm/> */}
      {/* </Container> */}

      <div className="girls-block">

        <div style={{
          background: "conic-gradient(from 15.29deg at 52.08% 70.06%, #598D87 -80.62deg, #8DBFBF 43.12deg, #598D87 279.38deg, #8DBFBF 403.12deg)"
        }} className="girl-block first-girl-block">
          <div className="girl-block-text">
            <div className="girl-title-big">
              <div className="hello">Hello world,</div> <div className="weare">we are</div>
            </div>
            <h1 className="cardano-title">Cardano Proxies</h1>
            <div className="girl-text-small">
              <div>
                <br />
                <div className="description-paper">
                  We’ve been minted on the blockchain with specific skills and abilities to improve the world and the Cardano ecosystem.


                </div>
                <br />
                <br />
                <strong className="minted-text">Minted, therefore we are.</strong>
              </div>
            </div>
            <div className="coming-soon">
Fly into our beehive            </div>
          </div>
          <div className="girl-block-image  first-girl-block-image">
            <img className="first-girl-image" src="main-page.png" />
          </div>
        </div>
        {/* <div className="girl-block second-girl-block">
          <div className="girl-block-image ">
            <img src="second_girl.png" />
          </div>
          <div className="girl-block-text">
            <div className="second-girl-title-big">
              About the project
            </div>
            <div className="second-girl-text-small">
              <div>
                Eat a rug and furry furry hairs everywhere oh no human coming lie on counter don't get off counter bury the poop bury it deep put toy mouse in food bowl run out of litter box at full speed . Meow meow pee in shoe head nudges . Cats making all the muffins lick sellotape but hunt anything that moves, yet damn that dog .
              </div>
              <strong>Toy mouse squeak roll over scratch the postman wake up lick paw.</strong>
            </div>
            <strong className="bottom-strong-title-second-girl">
              Toy mouse squeak roll over scratch the postman wake up lick paw.
            </strong>
          </div>
        </div> */}
        {/* <div className="girl-block first-girl-block register-block">
          <div className="girl-block-text">
            <div className="girl-title-big">
              --  Register  --
            </div>
            <small>All fields are mandatory</small>
            <div className="girl-text-small">
              <div>
                <span>Ada wallet</span>
                <input value={wallet} onChange={(e) => updateWallet(e.target.value)} placeholder="0x010101010101" />
                <span>Email</span>

                <input value={email} onChange={(e) => updateEmail(e.target.value)} placeholder="example@gmail.com" />
                <Recaptcha
                  sitekey="6LdFuu8UAAAAALT-oMuP77qqXPICvNyTodVer82C"
                  render="onload"
                  verifyCallback={verifyCallback}
                  onloadCallback={callback}
                  expiredCallback={() => updateRecaptchaToken(null)}
                // theme="dark"
                />

              </div>
            </div>
            {errorMessage == "" && !successful && <button onClick={submitRegistration} style={{ position: "relative" }} className="coming-soon">
              {!errorMessage && !successful && (submiting ?
                <div className="loader"></div> : "Submit registration")}

            </button>}
            {errorMessage != "" && <div className="error">{errorMessage}</div>}
            {successful && <div className="success">Congratulation, you were successful registrated. Please check your email to confirm registration.</div>}
          </div>
          <div className="girl-block-image  first-girl-block-image">
            <img className="first-girl-image" src="main_img.png" />
          </div>
        </div> */}
      </div>
      {/* <h1 style={{ textAlign: "center", color: "tomato" }}>Missing block |Slider| - debug mode</h1> */}
      <div className="slide d-sm-block d-xs-block d-md-none d-lg-none d-xl-none single-slide-alone"  >
        <div className="slide-content first-slider"><h1 className="slide-title">Our Proxies are part of our family of Beehives. 
</h1>
        <div className="slide-text-content">
        There are 5 hives that you will choose to support. Each NFT holder represents the Proxy they are supporting. Whether it is the Environment with Gaya, or the technology for Cyberiada. They can use their NFTs to vote and move the project in that direction.
We are beehives buzzing around to build a honeycomb of social values striving for good values. Why did we adopt the "beehive" system on our discord? The beehive, in nature, is usually led by a queen bee, an entity that represents fertility (in our case, productivity), community gathering and a sense of belonging.          <br /><br />
          {/* In order to fulfill our purposes we evolve through progressive versions of ourselves. */}
          <br /><br />

          <strong>Meet the Proxies.</strong>
        </div></div>
      </div>
      <MySlider />
      <SocialDinamics />
      <TimeLine />
      {/* <RoadMap /> */}
      {/* <h1 style={{ textAlign: "center", color: "tomato" }}>Error in block |RoadMap| - debug mode</h1> */}
      <BecomeAMember />
      <JoinComunityBlock />
      <Footer />
      {/* </div > */}
    </>
  );
}

export default Main;
