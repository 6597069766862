import Piechart from "./Piechart"
import "./style.scss";

const ChartComponent = (props) => {
    const statisticsInfo = [
        "Total supply - 21.000.000 (21 Million)",
        "Voting Power",
        "Purchase utility (use $PROXIE to partially/fully buy NFTs from Proxies or our partners’ collections, merchandise, participate in auctions, etc.)",
        "Paying for services (tip community members for help)",
        "Dex liquidity rewards",
        "Staking rewards",
    ]

    return (
        <div className="section-wrapper chart-section">
            <div className="section-subtitle chart-subtitle center-chart-title">
                The utility and governance token of Proxies NFT
            </div>
            <div className="section-content-wrapper title-section-content-wrapper">
                <div className="section-content">
                </div>
                <div className="section-content hide-mobile">

                </div>
            </div>

            <div className="new-feature-graphs">
                • Liquidity Farming • NFTs Staking rewards • Voting Power<br />
                • Paying for services & products • Mint Proxies NFTs • Auctions/raffles

            </div>

            <div className="section-content-wrapper">
                <div className="section-content">
                    <img className="chart-image" src="./image 2.1.png" />
                    {/* <ul>
                        {statisticsInfo.map(statistics => (
                            <li className="section-list">
                                {statistics}
                            </li>
                        ))}
                    </ul> */}
                </div>
                <div className="section-content">
                    <img className="chart-image" src="./image 2.png" />

                </div>
            </div>
        </div >
    );
}


export default ChartComponent;