
import DestinyImage from "./characters/Destiny 3.png"
import CyberiadaImage from "./characters/Cyberiada 2.png";
import EaselleImage from "./characters/Easelle_surealism.png"
import GayaImage from "./characters/Gayafor-website.png"
import MonedaImage from "./characters/MonedaDiamondHands.png"
import WisdomImage from "./characters/wisdom.png"

const NFTS = (props) => {
    let gradients = {
        "moneda_gradient": "linear-gradient(89.38deg, #8e9eab 10.8%, #eef2f3 49.91%)",
        "destiny_gradient": "linear-gradient(89.44deg, #ffe259 9.73%, #ffa751 41.68%)",
        "cyberiada_gradient": "linear-gradient( 271.04deg, rgba(111, 247, 232, 0.88) 50.23%, rgba(31, 126, 161, 0.88) 89.27%)",
        "easelle_gradient": "linear-gradient( 268.77deg, #feac5e 46.68%, #c779d0 70.85%, #4bc0c8 97.73%)",
        "gaya_gradient": "linear-gradient(266.38deg, #1d976c 67.23%, #93f9b9 97.2%)",
        "wisdom_gradient": "linear-gradient( 180deg, rgba(82, 193, 167, 0.88) 0%, rgba(166, 255, 234, 0.6424) 100%)",

    }
    const girls = [
        {
            "head_text": "Crypto & NFTs",
            "name": "Moneda",
            "description": "Thrift, Wealth and Education: Bright and elegant, Moneda supports the community in discussions regarding crypto & NFTs, investment opportunities and red flags.",
            "image": MonedaImage,
        },
        {
            "head_text": "Mental Health",
            "name": "Destiny",
            "description": "Give back, help and be kind. That's the core of humanity and Destiny reminds us that there are people who need help and we, as a community, have the power to contribute to a better life. Our love for Africa inspired us to create colourful attributes as a tribute for tribe cultures. ",
            "image": DestinyImage,
        },
        {
            "head_text": "Tech & Blockchain",
            "name": "Cyberiada",
            "description": "We’re still early, that's what she said. Cyberiada  illustrates our love for Sci-Fi and technological change. Along with the community she works on solutions that will improve the Cardano ecosystem and will help our community to grow and excel in all challenges we set and come across.  ",
            "image": CyberiadaImage,
        },
        {
            "head_text": "Art & Creativity",
            "name": "Easelle",
            "description": "Embodiment of Art. Easelle supports our community of artists and gets involved in helping our members to start their journey in the Cardano blockchain. Easelle challenges herself in a new environment, taking generative art to a new level.",
            "image": EaselleImage,
        },
        {
            "head_text": "Sustainability",
            "name": "Gaya",
            "description": "Goddess of the Earth, mother of all life. Building a community that forwards sustainable solutions to help and support the environment. Gaya strongly believes that together we can build a future where fresh air is not a luxury.",
            "image": GayaImage,
        },
        {
            "head_text": "Governance & Infinite evolution",
            "name": "Wisdom",
            "description": `The Wisdom NFT marks a new era for Proxies NFT, providing wisdom, clarity, coherence and a vision to a decentralized community.${'\n'} Our Wisdom has created a Proxies Bridge with the Ergo blockchain, allowing Wisdoms to travel on both chains.\n  Wisdom Proxies NFTs have been airdropped to the community as a token of gratitude, allowing members to apply for the Wisdom Council.\nThe Wisdom Proxie Metadata underwent a three-month refinement process with the help of Wisdom Seekers.\n Additionally, 100 Wisdoms are venturing into the Bitcoin Ordinals NFT realm and will land on the Bitcoin blockchain in April.`,
         // "description": "The Wisdom NFT marks a new era for Proxies NFT, providing wisdom, clarity, coherence, and vision to a decentralized community. Wisdom Proxies NFTs have been airdropped to the community as a token of gratitude, allowing members to apply for the Wisdom Council. The Wisdom Proxie Metadata underwent a three-month refinement process with the help of Wisdom Seekers.",
            "image": WisdomImage,
        },
    ]
    return (
        <div id="nfts" >
            {
                girls.map((girl, index) => {
                    return (
                        <div className={"girl" + ((index % 2 == 1) ? " left-girl" : " right-girl")+" nft-"+girl.name}>
                            <div className="girl-description">
                                <div className="girl-description-header">
                                    {girl.head_text}
                                </div>
                                <div className="girl-description-name" style={{"background-image": gradients[girl.name.toLowerCase()+"_gradient"]}}>
                                    {girl.name}

                                </div>
                                <div className="girl-description-description">
                                    {girl.description}

                                </div>
                            </div>
                            <div className="girl-image">
                                <img src={girl.image} />
                            </div>
                            {/* <div className="girl-bottom-line"></div> */}
                        </div>
                    )
                })
            }
        </div>
    )
}


export default NFTS;