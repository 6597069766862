import logo from './logo.svg';
import './App.css';
import Main from "./Pages/Main";
import Contact from "./Pages/Contact";
import Discover from "./Pages/Discover";
import { BrowserRouter, Route, Switch, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route exact path="/discover" element={<Discover/>} />
        <Route exact path="/contact" element={<Contact/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
