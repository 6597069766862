import { useState } from "react"
import Countdown from 'react-countdown';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const old_links = [
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR2ZmtneTB3YWZkNnpxdDNqOCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR2bmpyenY5emZndnFtcno5OCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR2NHk5Yzl5eTdrZnRzcThucyIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3cXZ5am5tMjd2ZTNkc3Z6NCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3anp5NzNrMmUxcHF5M3k2YyIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3dGpucTVkcG5iZm4wNXE4NiIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3cGJqdGMzMjA2eHIxeG1maCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3Z2dnMzVhZnpzMGdhanFtOSIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQxZGR3M2RjcGZnMnYyYjdqa2QwcCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFna3l4Zm5kejZra213c2JzOWVuZTFhbW4iLCJwaGFzZV9pZCI6IjAxZ2t6NDFkZTFmdjJwcDIwZDNreHZoOHo2IiwicHJpY2VfaWQiOiIwMWdrejQzcnM0ajd0N3Z6cWhyZTU5eDZreSIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
]

const links = [
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6ZGZ5bm5reXMwbWh2bmptcyIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6ZzliYmYzcjQ2Ym1uZ3Z5MyIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6ZHE5MWgxbTJlamtiY2N5ciIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6ZGE0cHZqenA2cmEzdmhiMiIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6aDh2Z3Ztc2JqcnYzenBxMCIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
    "https://buy.tangocrypto.com?mode=dark&q=eyJjb2xsZWN0aW9uX2lkIjoiMDFncTJrenA0N2F4Y2cxd3o5a2FmcmNwbmMiLCJwaGFzZV9pZCI6IjAxZ3EyemNxYzBoam5mc3ZjYjhlcXZrZ2tkIiwicHJpY2VfaWQiOiIwMWdxMnpjcWJ6NDc0YjNleGgxZWRjd2d5ciIsInR5cGUiOiJQaGFzZVNhbGUiLCJpc19yYW5kb20iOnRydWUsInRjYyI6InFYK3BnSVJtNEJuYlVUdlJxc3hrTDcvd0Ivd2pDQzRZbnphdDErTFhoOFBCSUFEZC96NDB2ZVVnVTNnNWFQalFKUVBHekhVVXZvQXRHT21NbXJJZGNTU1NPa0hwZzBVMGJ4ZXZ1cFR5QkpGNTNPOUl3WDl5eDBQVkFBZlZBZz09In0",
]

const MintingPopUp = props => {
    const [amount, updateAmount] = useState(1);
    const [max, updateMax] = useState(6);
    const [actualPrice, updatePrice] = useState(600);
    const [copied, updateCopied] = useState(false);
    const { updateOpenedPopUp, } = props;
    const mintAddress = "9fdonB1HqRZTKLvPuK8PHkV2aqxZSbW2MWurn7k6qCYqFbupidz";

    return (
        <div className="minting-pop-up">
            <div className="">
                <div className="menu-item" xs={2}><a href="#home"><img className='logo' src="proxies-logo.svg" /></a></div>
            </div>
            <div
                onClick={() => updateOpenedPopUp(false)}
                className="back-to-site">
                <small>{"<  "}</small> Back to site
            </div>
            <div className="content-container">
                {/* <div></div> */}
                <div className="calculator-form">
                    <div className="calc-description">
                        Introducing Wisdom Proxies NFT, a unique and exclusive art collection available on Ergo Blockchain. Only 203 pieces are available for purchase, as 997 have already been minted on the Cardano Blockchain. The Proxies Bridge launch allows bringing these NFTs to both Cardano and Ergo blockchains. Don't miss this chance to own a rare and valuable NFT piece from the Wisdom Proxies collection.
                        <br /><br />
                        Mint 1 Wisdom NFT for 35 $ERG per transaction. This mint includes 3 archetypes (1/1s). Good luck!
                        <br /><br />
                        Send exactly 35 $ERG to this address:
                        <br />
                        <div className="mint-adress-in-form">
                            <div className={"mint-string" + (copied ? " copied-field" : "")}>
                                {mintAddress}
                            </div>
                            <a
                                onClick={() => {
                                    navigator.clipboard.writeText(mintAddress);
                                    updateCopied(true);
                                    setTimeout(() => updateCopied(false), 5000);
                                }
                                }
                                className="mint-button-copy">
                                COPY
                            </a>
                        </div>
                        {/*                         
                        Price of "Deadly Sin" NFT: 600 Proxie + 4 ADA
                        <br />
                        <br />
                        Mint up to 6 per transaction. */}


                        {/* You can mint up to {max} Proxies per transaction. */}
                        {/* <br />
                        <br />
                        <br /> */}
                        {/* Send an exact amount of ADA to the address below to initiate the process. */}
                        {/* Mint up to 10 per transaction. */}

                    </div>
                    {/* <div className="amount-control">
                        <div className="amount-labe">Amount</div>
                        <div className="amount-incremental noselect">
                            <div className={"amount-minus" + (amount == 1 ? " not-allowed" : "")} onClick={() => updateAmount(amount => (amount > 1) ? amount - 1 : amount)}>-</div>
                            <div className="amount-value">{amount}</div>
                            <div className={"amount-plus" + (amount == max ? " not-allowed" : "")} onClick={() => updateAmount(amount => (amount < max) ? amount + 1 : amount)}>+</div>
                        </div>
                        <div onClick={() => updateAmount(max)} className="amount-max">
                            <div className="amount-border">

                                Max
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="calc-total">
                        <div className="calc-total-title">
                            Total
                        </div>
                        <div className="calc-total-value">
                            {actualPrice * amount}
                            {" $PROXIE + 4 ADA"}
                        </div>
                    </div>
                    <div className="mint-adress-in-form">
                        <div className={"mint-string" + (copied ? " copied-field" : "")}>
                            {mintAddress}
                        </div>
                        <a
                            // onClick={() => {
                            //     navigator.clipboard.writeText(mintAddress);
                            //     updateCopied(true);
                            //     setTimeout(() => updateCopied(false), 5000);
                            // }
                            // } 
                            href={links[amount - 1]}
                            target="_blank"
                            className="mint-button-copy">
                            Buy
                        </a>
                    </div> */}
                </div>

            </div>
        </div>
    )
}

const MainBanner = (props) => {
    const [openedPopUp, updateOpenedPopUp] = useState(false);
    const timeRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return (<div className="new-content-main-banner">
                April 2023: Witness the arrival of 100 Wisdom Ordinals on Bitcoin!<br />
                Discover all the details in our captivating Medium article <a href="https://theproxies.medium.com/proxies-bitcoin-nfts-and-ordinals-101-87e2d2b3e1df" target="_blank"><img className="img-social" src="mediu.png" /></a>
                <br />Join Discord for the raffles with $PROXIE and multiple other tokens. <a target="_blank" href="https://discord.gg/theproxies"><img className="img-social" src="discord-icon.png" /></a>

            </div>)
            // Render a completed state
            return (
                <div style={{ cursor: "pointer" }} onClick={() => updateOpenedPopUp(true)} className=" main-banner-button">
                    {/* <div className="mint-notification">
                        Whitelist mint: 30 April, 8 AM UTC (8 hours time slot)<br />
                        Public mint: 30 April, 4 PM UTC (48 hours time slot)
                    </div> */}
                    <div >Ergo Wisdom Mint 35 ERG</div>
                </div>)
            return (<div className=" not-allowed main-banner-button">
                {/* <div><span className="not-allowed">LBE Sold Out</span> </div> */}
                <div><span className="not-allowed">You can  Mint 7 Sins in {hours}h {minutes}m {seconds}s</span> </div>
            </div>)
        } else {
            //     // Render a countdown
            return (
                <div className=" not-allowed main-banner-button">
                    {/* <div><span className="not-allowed">Mint 7 Sins Sold Out</span> </div> */}
                    <div><span className="not-allowed">Ergo Wisdom Mint in {hours}h {minutes}m {seconds}s</span> </div>
                </div>
            )

        }
    }
    return (
        <>
            {
                openedPopUp && (
                    <MintingPopUp updateOpenedPopUp={updateOpenedPopUp} />
                )
            }
            <div id="home" className="girls-block">
                <div className="girl-block first-girl-block">
                    <div className="girl-block-text">
                        <div className="description-paper">
                            Marvellous. Crafted with passion. Engineered with Missions and Utilities.
                        </div>
                        <h1 className="cardano-title ">ProxiesNFT</h1>
                        {/* 1651305600000 */}
                        {/* <Countdown renderer={timeRenderer} date={1670967410} /> */}
                        {/* <Countdown renderer={timeRenderer} date={1670967720000} /> */}
                        <Countdown renderer={timeRenderer} date={1677855600000} />
                        {/* 1670967720000 */}

                    </div>
                    <div className="girl-block-image  first-girl-block-image">
                        <img className="first-girl-image" src="Cyberiada_banner_main.png" />
                    </div>
                </div>
            </div>
        </>
    )
}


export default MainBanner;