import { Row, Col, Container } from 'react-bootstrap';
import { useState } from "react";
import { NavLink } from 'react-router-dom';
import BuyModal from "./BoyModal";


const Header = () => {
    const [buyModal, updateBuyModal] = useState(false);
    const [openedMenu, updateOpenedMenu] = useState(false);

    return (
        <Row className="px-5 header-menu-new align-items-center">
            <Col onClick={() => updateOpenedMenu(true)} xs={3} className="d-md-none d-xs-block d-lg-none d-sm-block ham-menu">
                <div></div>
                <div></div>
                <div></div>
            </Col>
            <Col className={"d-md-none d-lg-none d-xl-none d-sm-block d-xs-block mobile-menu" + (openedMenu ? " opened-menu" : "")} xs={12}>
                <div onClick={() => updateOpenedMenu(false)} className="close-menu">X</div>
                <Col xs={12} className="menu-item align-items-center"><a onClick={() => updateOpenedMenu(false)} href="#proxiesapart">Mission</a></Col>
                <Col xs={12} className="menu-item  align-items-center"><a onClick={() => updateOpenedMenu(false)} href="#nfts">Proxies</a></Col>
                <Col xs={12} className="menu-item  align-items-center"><a onClick={() => updateOpenedMenu(false)} href="#sproxie">$PROXIE</a></Col>
                <Col xs={12} className="menu-item align-items-center"><a onClick={() => updateOpenedMenu(false)} href="#roadmap">Roadmap</a></Col>
                <Col xs={12} className="menu-item align-items-center"><a onClick={() => updateOpenedMenu(false)} target="_blank" href="https://proxies.cryptoraggies.io">Shop</a></Col>

                <Col md={1} lg={1} className="flex align-center social justify-content-between noPadding">
                    <a href="https://twitter.com/Proxies_NFT" target="_blank"><img className="img-social" src="twitter-icon.png" /></a>
                    <a href="https://discord.gg/e9NahrBAWG" target="_blank"><img className="img-social" src="discord-icon.png" /></a>
                    <a target="_blank" href="https://medium.com/@theproxies"><img className="img-social" src="mediu.png" /></a>
                    <a target="_blank" href="https://www.youtube.com/@cardanoproxiesnft669"><img className="img-social" src="youtube.png" /></a>


                </Col>
            </Col>
            <Col className="menu-item d-md-block  d-lg-block  d-none d-sm-none   align-items-center" xs={2}><a href="#home"><img className='logo' src="proxies-logo.svg" /></a></Col>
            <Col className="d-md-none d-lg-none d-xl-none d-sm-block d-xs-block" xs={6}><a href="#home"><img className='logo-mobile' src="proxies-logo.svg" /></a></Col>


            <Col className="d-none d-sm-block" md={5} lg={5}></Col>


            <Col className="d-md-none d-sm-block d-lg-none" xs={4}></Col>
            <Col className="menu-item d-md-block  d-none d-sm-none   align-items-center"><a href="#proxiesapart">Mission</a></Col>
            <Col className="menu-item d-md-block  d-none d-sm-none  align-items-center"><a href="#nfts">Proxies</a></Col>
            <Col className="menu-item d-md-block   d-none d-sm-none  align-items-center"><a href="#sproxie">$PROXIE</a></Col>
            <Col className="menu-item d-md-block  d-none d-sm-none  align-items-center"><a href="#roadmap">Roadmap</a></Col>
            <Col className="menu-item d-md-block   d-none d-sm-none  align-items-center"><a target="_blank" href="https://proxies.cryptoraggies.io">Shop</a></Col>
            <Col md={1} lg={1} className="flex d-sm-none d-md-block align-center social d-none justify-content-between noPadding">
                <a href="https://twitter.com/Proxies_NFT" target="_blank"><img className="img-social" src="twitter-icon.png" /></a>
                <a href="https://discord.gg/e9NahrBAWG" target="_blank"><img className="img-social" src="discord-icon.png" /></a>
                <a target="_blank" href="https://medium.com/@theproxies"><img className="img-social" src="mediu.png" /></a>
                <a target="_blank" href="https://www.youtube.com/@cardanoproxiesnft669"><img className="img-social" src="youtube.png" /></a>

            </Col>
            {buyModal && <BuyModal close={() => updateBuyModal(false)} />}
        </Row>
    )
}
export default Header;