
const FlyInto = () => {
    return (<div className="fly-into">

        <div className="fly-into-column title-proxies-fly">
            <img src="hive.png" />
        </div>
        <div className="fly-into-column description-fly">
            <div >
                <span className="fly-title">

                Fly into the<br/> 
                </span>
                <span className="fly-title">
 Beehive
                </span>
            </div>
            <div className="fly-description">
            A networking channel where you learn, create and share experience with like minded individuals.
            </div>
        </div>
    </div>)
}


export default FlyInto;