import "./styles.css";
import React from "react"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Components/HeadMenu";
import NFTS from "./Components/NFTS";
import MainBanner from "./Components/MainBanner";
import ProxiesArePart from "./Components/ProxiesArePart";
import FlyInto from "./Components/FlyIntoBeehive";
import ChartComponent from "./Components/ChartComponent/ChartComponent"
import Sproxie from "./Components/SproxieVote";
import RoadMap from "./Components/RoadMap";
import ArtSection from "./Components/ArtSection";
import Footer from "./Components/Footer";
import "./Components/styles.scss"


function JoinComunityBlock() {
  return (
    <div id="joinus" className="join-comunity">
      <div className="treasure">Community is our treasure</div>
      <div>
        <div className="join-comunity-text">Join us!</div>
      </div>
      <div>

        <div>
          <a target="_blank" href="https://twitter.com/Proxies_NFT"><img className="img-social" src="twitter-icon.png" /></a>
          <a target="_blank" href="https://discord.gg/theproxies"><img className="img-social" src="discord-icon.png" /></a>
          <a target="_blank" href="http://instagram.com/cardanoproxies/"><img className="img-social" src="instagram-icon.png" /></a>
          <a target="_blank" href="https://medium.com/@theproxies"><img className="img-social" src="mediu.png" /></a>
          <a target="_blank" href="https://www.youtube.com/@cardanoproxiesnft669"><img className="img-social" src="youtube.png" /></a>
          <a target="_blank" href="https://linktr.ee/proxiesnft"><img className="img-social" src="linktree.png" /></a>
        </div>
      </div>
    </div>
  )
}



function Main() {
  return (
    <>
      <Header />
      <MainBanner />
      <ProxiesArePart />
      <FlyInto />
      <NFTS />
      <Sproxie />
      <ChartComponent />
      <RoadMap />
      <ArtSection />
      <JoinComunityBlock />
      <Footer />
    </>
  );
}

export default Main;
