
const ProxiesArePart = () => {
    return (<div id="proxiesapart" className="proxiesarepart">

        <div className="proxiesarepart-column title-proxies-part">
            Proxies are part of our family of Beehives
        </div>
        <div className="proxiesarepart-column description-part">
        One of a kind detailed generative art in a collection of 6000 High-fidelity artworks. Inspired by <strong>Sci-Fi, Nature, African culture</strong> and limitless creativity. A variety of 340+ individually crafted traits, linked to the characters quintessence. <br/><br/> Proxies are six fearless, alluring characters who start their journey on the Cardano blockchain. They cover 6 major areas: <strong>Crypto & NFTs, Mental Health, Blockchain Tech, Art, Sustainability, Governance & Infinite Evolution</strong>. They pave the way towards decentralization and gather experience within the community. Together, they built a networking system - the Beehives, where each Proxie personifies the hive she is responsible for.



        </div>
    </div>)
}


export default ProxiesArePart;