import Image1 from "./roadmap/1-active.svg";
import Image2 from "./roadmap/2-active.svg";
import Image3 from "./roadmap/3-active.svg";
import Image4 from "./roadmap/4-active.svg";
import Image5 from "./roadmap/5-active.svg";
import Image6 from "./roadmap/6-inactive.svg";
import Image6A from "./roadmap/6-activenew.svg";
import Image7 from "./roadmap/7-inactive.svg";
import Image7A from "./roadmap/7-active.svg";
import Image8 from "./roadmap/8-inactive.svg";
import Image9 from "./roadmap/9-inactive.svg";
import Image10 from "./roadmap/10-inactive.svg";
import Image11 from "./roadmap/11-inactive.svg";
import Image12 from "./roadmap/12-inactive.svg";
import Image13 from "./roadmap/13-inactive.svg";
import Image14 from "./roadmap/14-inactive.svg";
import Image15 from "./roadmap/15-inactive.svg";
import ArrowNext from "./roadmap/Arrow 1.svg"
import ArrowPrev from "./roadmap/Arrow 2.svg"

import { useRef, useLayoutEffect, useState } from "react";


const RoadMap = () => {
    const firstFull = useRef(null);
    const lastFull = useRef(null);
    const lastEmpty = useRef(null);
    const [activeLine, updateActiveLine] = useState(false);
    const [inactiveLine, updateInacriveLine] = useState(false);
    const roadMapRef = useRef(null);

    useLayoutEffect(() => {
        const firstPos = firstFull?.current.offsetLeft;
        const lastPos = lastFull?.current.offsetLeft;
        updateActiveLine(lastPos - firstPos);
        const lastPosE = lastEmpty?.current.offsetLeft;
        updateInacriveLine(lastPos - firstPos);
        console.log(lastPosE)
        console.log(lastPos)
    }, []);

    const roadPoints = [
        {
            "image": Image1,
            "title": "Q1/Q2",
            "description": "•Proxies First CrossChain Bridge\n•$PROXIE Airdrop for Wisdom Holders\n•Proxies Gitbook"
        },
        {
            "image": Image2,
            "title": "Q2/Q3",
            "description": `•Multichain Exploration (surprise project)\n •Conundrums Earrings Airdrop and utility\n•Merch\n•$PROXIE Staking            `
        },
        {
            "image": Image3,
            "title": "Q3/Q4",
            "description": "•AI Exploration (Not Art) \n•Male Proxies \n•$ADA/$PROXIE Payments in physical locations            "
        },

    ]
    const scrollLeft = () => {
        roadMapRef.current.scrollLeft -= window.innerWidth * 0.7;

    }
    const scrolRight = () => {
        roadMapRef.current.scrollLeft += window.innerWidth * 0.7;
    }
    const getRef = (index) => {
        if (index == 0) {
            return firstFull;
        }
        if (index == 2) {
            return lastFull;
        }
        if (index == 1) {
            return lastEmpty;
        }
        return null;
    }

    return (
        <div className="road" id="roadmap">
            <div className="road-head">
                <div className="road-head-title">
                    Roadmap
                </div>
            </div>
            <div className="road-map" ref={roadMapRef}>
                {
                    roadPoints.map((point, index) => {
                        return (
                            <div ref={getRef(index)} className={"road-map-point point-" + index} id={"point-" + index}>
                                <div className="road-map-point-image-container">
                                    {
                                        index == -1 && <div style={{ width: (activeLine != false ? activeLine + "px" : 0) }} className="line-in-image"></div>
                                    }
                                    {
                                        index == 0 && <div style={{ width: (inactiveLine != false ? inactiveLine + "px" : 0) }} className="inactive-line line-in-image"></div>
                                    }

                                    <img src={point.image} className="road-map-point-image" />
                                </div>
                                <div className="point-title">
                                    {point.title}
                                </div>
                                <div className="point-description">
                                    {point.description}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="road-controll">
                <div className="road-controll-prev" onClick={scrollLeft}>
                    <div className="road-button prev-button">
                        <img src={ArrowPrev} />
                    </div>
                </div>
                <div className="road-controll-next" onClick={scrolRight}>
                    <div className="road-button next-button">
                        <img src={ArrowNext} />
                    </div>
                </div>
            </div>
        </div>)
}


export default RoadMap;