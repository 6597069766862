import Popup from 'reactjs-popup';
import React from "react";
import { useState, useEffect, useRef } from "react";
import "./buy_styles.css";
import onClickOutside from 'react-onclickoutside'


const ModalBuy = (props) => {
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const [clicked, updateClicked] = useState(false);
    useEffect(() => {
        if (clicked) {
            setTimeout(() => updateClicked(false), 2000);
        }
    }, [clicked])
    const address = "addr1v9k0dwry8rne84wl9a4hczupwy24z5ahh697p3e262kj96cdsz6yp";

    const listenForOutsideClick = (listening, setListening, menuRef, setIsOpen) => {
        return () => {
            if (listening) return;
            if (!menuRef.current) return;
            setListening(true);
            [`click`, `touchstart`].forEach((type) => {
                document.addEventListener(`click`, (evt) => {
                    if (menuRef && menuRef?.current?.contains(evt.target)) return;
                    setIsOpen(false);
                });
            });
        }
    }
    useEffect(listenForOutsideClick(
        listening,
        setListening,
        menuRef,
        setIsOpen,
    ));
    const close = () => {
        [`click`, `touchstart`].forEach((type) => {
            document.removeEventListener(`click`, (evt) => {
                if (menuRef && menuRef.current?.contains(evt.target)) return;
                setIsOpen(false);
            });
        });
        props.close();
    }
    useEffect(() => {
        if (!isOpen) {
            props.close();
        }
    }, [isOpen])
    return (
        <div ref={menuRef} id="modal" >
            <img onClick={close} id="close-modal" src="./close.png" />
            <div className='main-title'>Your Proxie is one step away from being minted</div>
            <div id="modal-content">

                <div id="modal-first-column">
                    <div className="mint-notice">You can mint up to 5 Proxies per transaction.
                        <br />
                        Send an exact amount of ADA to the below address<br /> and let the magic happen :) </div>
                    <div className="mint-notice mobile-notice">You can mint up to 5 Proxies per transaction.

                        Send an exact amount of ADA to the below address and let the magic happen :) </div>
                    <div id="title-mint">
                        {/* <div id="title-mint-text">Mint 1 Proxy</div> */}
                        {/* <div id="ada-c">₳100</div> */}
                    </div>
                    <div className='mint-values'>
                        <div className='mint-value'>
                            <div id="copy-addres-block">

                                <div onClick={() => { navigator.clipboard.writeText(address); updateClicked(true); }} id="address-mint">
                                    {address}
                                </div>
                                <div className={clicked ? "clicked-copy" : ""} onClick={() => { navigator.clipboard.writeText(address); updateClicked(true); }} id="copy-address">
                                    COPY
                                </div>
                            </div>
                        </div>
                        <div className='mint-value'>
                            <div className='mint-value-name'>
                                Mint 1 Proxie
                            </div>
                            <div className='mint-value-price'>
                                ₳100
                            </div>
                        </div>
                        <div className='mint-value'>
                            <div className='mint-value-name'>
                                Mint 2 Proxie
                            </div>
                            <div className='mint-value-price'>
                                ₳200
                            </div>
                        </div><div className='mint-value'>
                            <div className='mint-value-name'>
                                Mint 3 Proxie
                            </div>
                            <div className='mint-value-price'>
                                ₳300
                            </div>
                        </div><div className='mint-value'>
                            <div className='mint-value-name'>
                                Mint 4 Proxie
                            </div>
                            <div className='mint-value-price'>
                                ₳400
                            </div>
                        </div><div className='mint-value'>
                            <div className='mint-value-name'>
                                Mint 5 Proxie
                            </div>
                            <div className='mint-value-price'>
                                ₳500
                            </div>
                        </div>
                    </div>


                    {/* <img id="qr" src="./Mobile_RO_Wikipedia_QR_code.png" /> */}
                </div>
                <div id="modal-second-column">
                    <div className="important-text">
                        Important!
                    </div>

                    <ul>
                        <li>You must use a Shelley-era Cardano Wallet.</li>
                        <li><b>DO NOT</b> send <b>ADA</b> from an exchange!</li>
                        <li>Send the exact amount of ADA otherwise will be refunded</li>
                        <li>If your transaction is not equal to <strong>100</strong>, <strong>200</strong>, <strong>300</strong>, <strong>400</strong>, <strong>500</strong> or your transaction is received after Cardano Proxies are sold out, then your transaction will be refunded.</li>
                    </ul>
                </div>

            </div>
        </div >
    )
}

export default ModalBuy;