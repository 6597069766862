

const Sproxie = (props) => {

    return (
        <div id="sproxie" >
            <div className="sproxie-content-block">
                <div className="proxie-title">$PROXIE</div>
                <div className="proxie-description">
                    Earn & Vote.
                    <br />
                    Trade or Pay.
                    <br />
                    Mint NFTs.
                </div>
            </div>
        </div>

    )
}


export default Sproxie;