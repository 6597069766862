import { useEffect, useState } from "react";
import Image1 from "./Art/1.1.png";
import Image2 from "./Art/2.1.png";
import Image3 from "./Art/3.1.png";
import Image4 from "./Art/4.1.png";
import Image5 from "./Art/5.1.png";
import Image6 from "./Art/6.1.png";

const ArtSection = (props) => {
    const [windowWidth, setWindowWidth] = useState(0);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);
    return (
        <>
            <div id="art-section" >
                {

                    windowWidth <= 767 && (
                        <>
                            <div className="main-message">
                                How to use Proxies NFTs?
                            </div>
                            <div className="art-section-column second">
                                <div className="membership-title">
                                    Proxies membership
                                </div>
                                <div className="membership-content">
                                    Partake in community networks and gain access to information & discussions in verified channels. Chat with people with the same interests and learn how to create your own journey on the Cardano blockchain.
                                    <div className="line-delimiter"></div>
                                    Meet the community that has everything you need for potential partnerships and collaboration for your future projects.
                                    <div className="line-delimiter"></div>
                                    Participate and vote on projects, ideas and Proxies’ direction.
                                    <div className="line-delimiter"></div>
                                    Discover each Proxie’s utility and benefit from being a member.
                                    <div className="line-delimiter"></div>
                                    Owners of Proxies NFTs are farming $PROXIE and $HEXO on CardanoLands.com by non custodial NFT staking.
                                </div>
                            </div>
                            <div className="art-section-column first">



                                <div className="trade-section">
                                    <a target="_blank" href="https://linktr.ee/marketproxies" className="trade-title trade-link">
                                        TRADE <img className="new-tab" src="new-tab.png" />
                                    </a>
                                    <a target="_blank" href="https://cardanolands.com/staking/proxies" className="trade-title trade-link">
                                        STAKING <img className="new-tab" src="new-tab.png" />
                                    </a>
                                    <a className="trade-title">
                                        AIRDROPS <div className="post-text">are on the way for Wisdom NFT</div>
                                    </a>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    windowWidth >= 767 && (
                        <>
                            <div className="art-section-column first">
                                <div className="main-message">
                                    How to use Proxies NFTs?
                                </div>

                                <div className="trade-section">
                                    <a target="_blank" href="https://linktr.ee/marketproxies" className="trade-title trade-link">
                                        TRADE <img className="new-tab" src="new-tab.png" />
                                    </a>
                                    <a target="_blank" href="https://cardanolands.com/staking/proxies" className="trade-title trade-link">
                                        STAKING <img className="new-tab" src="new-tab.png" />
                                    </a>
                                    <a className="trade-title">
                                        AIRDROPS <div className="post-text">are on the way for Wisdom NFT</div>
                                    </a>
                                    {/* <div className="trade-message">
                                        Buy and sell on the secondary market. Proxies are verified on all main marketplaces like:
                                        <br />
                                        <br />
                                        <br />
                                        <a target="_blank" href="https://www.jpg.store/collection/cardanoproxies">www.jpg.store  <span class="glyphicon glyphicon-new-window"></span></a><br />
                                        <a target="_blank" href="https://cnft.io/marketplace?project=CardanoProxies">www.cnft.io  <span class="glyphicon glyphicon-new-window"></span></a><br />
                                        <a target="_blank" href="https://www.genesishouse.io/collections/3117">www.genesishouse.io  <span class="glyphicon glyphicon-new-window"></span></a><br />
                                        <a target="_blank" href="https://artifct.app/collection/CardanoProxies">www.artifct.app  <span class="glyphicon glyphicon-new-window"></span></a><br />
                                    </div> */}
                                </div>
                            </div>
                            <div className="art-section-column second">
                                <div className="membership-title">
                                    Proxies membership
                                </div>
                                <div className="membership-content">
                                    Partake in community networks and gain access to information & discussions in verified channels. Chat with people with the same interests and learn how to create your own journey on the Cardano blockchain.
                                    <div className="line-delimiter"></div>
                                    Meet the community that has everything you need for potential partnerships and collaboration for your future projects.
                                    <div className="line-delimiter"></div>
                                    Participate and vote on projects, ideas and Proxies’ direction.
                                    <div className="line-delimiter"></div>
                                    Discover each Proxie’s utility and benefit from being a member.
                                    <div className="line-delimiter"></div>
                                    Owners of Proxies NFTs are farming $PROXIE and $HEXO on CardanoLands.com by non custodial NFT staking.
                                </div>
                            </div>
                        </>
                    )}
            </div>

            <div className="collection-art">
                <div className="collection-art-title">
                    Collect Art
                </div>
                {

                    windowWidth >= 767 && (<div className="collection-art-content">
                        <div>

                            Our breath-taking Proxies have already stolen many hearts. Are you into sci-fi, African culture, haute couture or you just love to see our artists’ creative exploration? There are many reasons why the Cardano community loves the Proxies’ art, find what touches you deeply and don’t forget to ZOOM IN!
                        </div>
                    </div>)
                }
            </div>
            <div className="collection-art-images-block">

                <div className="collection-art-images">
                    <div className="collection-art-image"><img src={Image1} /></div>
                    <div className="collection-art-image"><img src={Image2} /></div>
                    <div className="collection-art-image"><img src={Image3} /></div>
                    <div className="collection-art-image"><img src={Image4} /></div>
                    <div className="collection-art-image"><img src={Image5} /></div>
                    <div className="collection-art-image"><img src={Image6} /></div>
                </div>
            </div>
            {
                windowWidth <= 767 && (
                    <div className="collection-art">
                        <div className="collection-art-title">
                        </div>

                        <div className="collection-art-content">
                            <div>

                                Our breath-taking Proxies have already stolen many hearts. Are you into sci-fi, African culture, haute couture or you just love to see our artists’ creative exploration? There are many reasons why the Cardano community loves the Proxies’ art, find what touches you deeply.
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}


export default ArtSection;