
import { useEffect, useState } from "react";
import Image1 from "./Art/1.png";
import Image2 from "./Art/2.png";
import Image3 from "./Art/3.png";
import Image4 from "./Art/4.png";
import Image5 from "./Art/5.png";


const Footer = (props) => {
    const [windowWidth, setWindowWidth] = useState(0);
    const [opened, updateOpened] = useState(false);
    let resizeWindow = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);
    return (
        <div id="footer" className="new-footer">
            {
                windowWidth >= 767 && (<>
                    <div className="f-col coloana-1">
                        <div className="">Policy IDs: {opened ? <span className="togle-action" onClick={() => updateOpened(!opened)}>Close the list</span> : <span className="togle-action" onClick={() => updateOpened(!opened)}>Open the list</span>}</div>
                        <div className={"f-proxie-list f-" + (opened ? "opened" : "closed")}>

                            <div className="f-proxies-title">Cardano Blockchain:</div>
                            <br />

                            <strong>ProxiesNFT:</strong><br />
                            6ce2d3c06e323d522d0d1c67b4ebae39d62549acc633733e357d6027
                            <br /><br />
                            <strong>ProxiesNFT Specials:</strong><br />
                            00c5ee75c364ae10409040172538e3d5d295aafc18bfbf4d5e26f352
                            <br /><br />
                            <strong>Proxie Womb (WL):</strong><br />
                            2a6c1ea5b4805b1aabae53758e9e2e6623e7332cded72fef7e1ff8bf
                            <br /><br />
                            <strong>$PROXIE Token:</strong><br />
                            20cd68533b47565f3c61efb39c30fdace9963bfa4c0060b613448e3c
                            <br /><br />
                            <strong>ProxiesNFT Parallels:</strong><br />
                            b59e3852774afe2a6bd8648fe46c345cdea1f0095a8e1f1744909ecc
                            <br /><br />

                            <strong>Proxies Time Capsule 1:</strong><br />
                            d4105b6e6119c1b795faab8a62e292673b00e4b266353ac9c1ddae99
                            <br /><br />
                            <strong>Proxies Time Capsule 2:</strong><br />
                            6b7096aa67f471ce46d2e32206d50c60271dd3ec18727cd1ee946d05
                            <br /><br />
                            <strong>Proxies Time Capsule 3:</strong><br />
                            e19f96127279bc585132893c531f807982f8e40f0d51946d75d7a669
                            <br /><br />
                            <strong>Wisdom is Coming</strong><br />
                            fc9078efe20be7fea52344a14dad746427985527da84298df4788003
                            <br /><br />
                            <strong>Wisdom Raffle Ticket</strong><br />
                            12f74f0f6a8812e3cf1134b27577d7bdb031a4634ae7ec5f5884e94a
                            <br /><br />
                            <strong>Wisdom Proxies</strong><br />
                            354da60572a38d765bdc6851f8d14e7c27f15e7b94ebeb4cd8e6b5aa
                            <br /><br />
                            <strong>$PROXIE DeFi</strong><br />
                            ff9c4b9a43f78387e2404a9d7bee9d1124633d9faf1fb363875149b9
                            <br /><br />
                            <strong>7 Deadly Sins in your Wallet</strong><br />
                            8bbab66b0d14573b3872a3cfb5e208f188b7f3ae4e247d2a13387bc7
                            <br /><br />

                            <strong>Wisdom is Coming</strong><br />
                            fc9078efe20be7fea52344a14dad746427985527da84298df4788003
                            <br /><br />
                            <strong>Proxies Ordinals Exchange Necklace</strong><br />
                            b239572d19ee941083b3f7c33ab03a33ce626a1796e916d3e39a4d42

                            <div className="f-proxies-title">Ergo Blockchain:</div>
                            <br />

                            <strong>$PROXIE token</strong><br />
                            9g6j89dUcuHGpFSmYAivcpGs3zAaREenPCMaQnvGHVnyH3HCQAL
                            <br /><br />
                            <strong>Ergo Wisdom NFT</strong><br />
                            9g6j89dUcuHGpFSmYAivcpGs3zAaREenPCMaQnvGHVnyH3HCQAL
                            <br /><br />

                        </div>
                        <div className="footer-logos">

                            <img className="cardano-logo" src="cardano.png" />
                            <img className="cardano-logo" src="image 10.png" />
                        </div>                    </div>
                    <div className="f-col coloana-2">
                        {/* <div className="col-2-field">Main</div>
                        <div className="col-2-field">About Proxies</div>
                        <div className="col-2-field">Mission</div>
                        <div className="col-2-field">Utilities</div>
                        <div className="col-2-field">Roadmap</div>
                        <div className="col-2-field">Ways to use</div> */}
                    </div>
                    <div className="f-col coloana-3">
                        {/* <div className="col-3-field"><a href="CardanoProxies WP.pdf">Light paper</a></div> */}
                        {/* <div className="col-3-field">Whitepaper</div>
                        <div className="col-3-field"></div>
                        <div className="col-3-field">Team</div> */}
                    </div>
                    <div className="f-col coloana-4">
                        <div className="col-4-field">Social links</div>

                        <div className="f-social-links">
                            <a target="_blank" href="https://twitter.com/Proxies_NFT"><img className="img-social" src="twitter-icon.png" /></a>
                            <a target="_blank" href="https://discord.gg/theproxies"><img className="img-social" src="discord-icon.png" /></a>
                            <a target="_blank" href="http://instagram.com/cardanoproxies/"><img className="img-social" src="instagram-icon.png" /></a>
                            <a target="_blank" href="https://medium.com/@theproxies"><img className="img-social" src="mediu.png" /></a>
                        </div>
                    </div>
                </>)
            }

            {
                windowWidth <= 767 && (<>
                    <div className="mobile-row-footer">
                        {/* <div className="f-col coloana-2"> */}
                        {/* <div className="col-2-field">Main</div>
                            <div className="col-2-field">About Proxies</div>
                            <div className="col-2-field">Mission</div>
                            <div className="col-2-field">Utilities</div>
                            <div className="col-2-field">Roadmap</div>
                            <div className="col-2-field">Ways to use</div> */}
                        {/* </div> */}
                        <div className="f-col coloana-3">
                            {/* <div className="col-3-field">Light paper</div> */}
                            {/* <div className="col-3-field"><a href="CardanoProxies WP.pdf">Light paper</a></div> */}

                            {/* <div className="col-3-field">Whitepaper</div>
                            <div className="col-3-field"></div>
                            <div className="col-3-field">Team</div> */}
                        </div>
                        <div className="f-col coloana-4">
                            <div className="col-4-field">Social links</div>

                            <div className="f-social-links">
                                <a target="_blank" href="https://twitter.com/Proxies_NFT"><img className="img-social" src="twitter-icon.png" /></a>
                                <a target="_blank" href="https://discord.gg/theproxies"><img className="img-social" src="discord-icon.png" /></a>
                                <a target="_blank" href="http://instagram.com/cardanoproxies/"><img className="img-social" src="instagram-icon.png" /></a>
                                <a target="_blank" href="https://medium.com/@theproxies"><img className="img-social" src="mediu.png" /></a>
                            </div>
                        </div>
                    </div>

                    <div className="f-col coloana-1">
                        <div className="">Policy IDs: {opened ? <span className="togle-action" onClick={() => updateOpened(!opened)}>Close the list</span> : <span className="togle-action" onClick={() => updateOpened(!opened)}>Open the list</span>}</div>
                        <div className={"f-proxie-list f-" + (opened ? "opened" : "closed")}>

                            <div className="f-proxies-title">Cardano Blockchain:</div>
                            <br />

                            <strong>ProxiesNFT:</strong><br />
                            6ce2d3c06e323d522d0d1c67b4ebae39d62549acc633733e357d6027
                            <br /><br />
                            <strong>ProxiesNFT Specials:</strong><br />
                            00c5ee75c364ae10409040172538e3d5d295aafc18bfbf4d5e26f352
                            <br /><br />
                            <strong>Proxie Womb (WL):</strong><br />
                            2a6c1ea5b4805b1aabae53758e9e2e6623e7332cded72fef7e1ff8bf
                            <br /><br />
                            <strong>$PROXIE Token:</strong><br />
                            20cd68533b47565f3c61efb39c30fdace9963bfa4c0060b613448e3c
                            <br /><br />
                            <strong>ProxiesNFT Parallels:</strong><br />
                            b59e3852774afe2a6bd8648fe46c345cdea1f0095a8e1f1744909ecc
                            <br /><br />

                            <strong>Proxies Time Capsule 1:</strong><br />
                            d4105b6e6119c1b795faab8a62e292673b00e4b266353ac9c1ddae99
                            <br /><br />
                            <strong>Proxies Time Capsule 2:</strong><br />
                            6b7096aa67f471ce46d2e32206d50c60271dd3ec18727cd1ee946d05
                            <br /><br />
                            <strong>Proxies Time Capsule 3:</strong><br />
                            e19f96127279bc585132893c531f807982f8e40f0d51946d75d7a669
                            <br /><br />
                            <strong>Wisdom is Coming</strong><br />
                            fc9078efe20be7fea52344a14dad746427985527da84298df4788003
                            <br /><br />
                            <strong>Wisdom Raffle Ticket</strong><br />
                            12f74f0f6a8812e3cf1134b27577d7bdb031a4634ae7ec5f5884e94a
                            <br /><br />
                            <strong>Wisdom Proxies</strong><br />
                            354da60572a38d765bdc6851f8d14e7c27f15e7b94ebeb4cd8e6b5aa
                            <br /><br />
                            <strong>$PROXIE DeFi</strong><br />
                            ff9c4b9a43f78387e2404a9d7bee9d1124633d9faf1fb363875149b9
                            <br /><br />
                            <strong>7 Deadly Sins in your Wallet</strong><br />
                            8bbab66b0d14573b3872a3cfb5e208f188b7f3ae4e247d2a13387bc7
                            <br /><br />

                            <strong>Wisdom is Coming</strong><br />
                            fc9078efe20be7fea52344a14dad746427985527da84298df4788003
                            <br /><br />
                            <strong>Proxies Ordinals Exchange Necklace</strong><br />
                            fc9078efe20be7fea52344a14dad746427985527da84298df4788003

                            <div className="f-proxies-title">Ergo Blockchain:</div>
                            <br />

                            <strong>$PROXIE token</strong><br />
                            9g6j89dUcuHGpFSmYAivcpGs3zAaREenPCMaQnvGHVnyH3HCQAL
                            <br /><br />
                            <strong>Ergo Wisdom NFT</strong><br />
                            9g6j89dUcuHGpFSmYAivcpGs3zAaREenPCMaQnvGHVnyH3HCQAL
                            <br /><br />
                        </div>
                        <div className="footer-logos">

                            <img className="cardano-logo" src="cardano.png" />
                            <img className="cardano-logo" src="image 10.png" />
                        </div>

                    </div>
                </>)
            }
        </div>
    )
}


export default Footer;